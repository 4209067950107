import api from "@/base/utils/request";

//保存
export const getSave = data => {
    return api({
        url: "/admin/signup/signup/save",
        method: "post",
        data
    });
};
//获取
export const setDetail = data => {
    return api({
        url: "/admin/signup/signup/detail",
        method: "post",
        data
    });
};