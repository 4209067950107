<template>
  <div v-loading="loading">
    <el-main v-show="!isNext && showNext">
      <el-form size="medium" :rules="rules" ref="form" label-width="100px" label-position="right">
        <el-form-item label="报名标题" prop="title">
          <el-input v-model="title" placeholder="请输入报名标题"></el-input>
        </el-form-item>
        <el-form-item label="表单提交限制" prop="limit">
          <el-checkbox-group v-model="limitList">
            <el-checkbox label="person" name="limit">限定每人量</el-checkbox>
            <el-checkbox label="day" name="limit">限定每日量</el-checkbox>
            <el-checkbox label="total" name="limit">限定全部总量</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="报名时间" prop="date">
          <div class="flex">
            <el-date-picker v-model="start_time" placeholder="开始时间" type="datetime" value-format="timestamp"
              format="yyyy-MM-dd HH:mm" time-arrow-control>
            </el-date-picker>
            <span class="inline-block">至</span>
            <el-date-picker v-model="end_time" placeholder="结束时间" type="datetime" value-format="timestamp"
              format="yyyy-MM-dd HH:mm" time-arrow-control>
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="每人报名数" prop="person" v-show="config[0].status == 1">
          <el-input v-model="config[0].num" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="每日报名数" prop="day" v-show="config[1].status == 1">
          <el-input v-model="config[1].num" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="报名总数" prop="total" v-show="config[2].status == 1">
          <el-input v-model="config[2].num" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="报名头图">
          <DefaultImageSelector v-model="images" :DefaultImage="DefaultImage" :width="100" :height="100"
            :border-radius="4" fit="contain">
            <p slot="info" class="img-tips">建议上传尺寸比例为1035*360</p>
          </DefaultImageSelector>
        </el-form-item>
        <el-form-item label="成功标题">
          <el-input v-model="success_title" placeholder="报名成功"></el-input>
          <div class="note m-0"><span class="el-icon-warning"></span> 自定义报名成功的标题，最多30字</div>
        </el-form-item>
        <el-form-item label="报名标题">
          <el-input v-model="success_content" placeholder="您已完成本次报名"></el-input>
          <div class="note m-0"><span class="el-icon-warning"></span> 自定义报名成功的内容，最多60字</div>
        </el-form-item>
        <el-form-item label="分享图">
          <single-media-wall v-model="share_image" :width="100" :height="100" :border-radius="4" add-text="">
          </single-media-wall>
          <div class="note m-0"><span class="el-icon-warning"></span> 建议上传尺寸比例为500*400</div>
        </el-form-item>
      </el-form>
    </el-main>
    <el-main v-show="isNext">
      <!--                <label class="save-title m-b-30">{{id == 0 ? '新增-':''}}报名信息</label>-->
      <el-table id="sortTable" ref="table1" :data="form_config" row-key="timestamp" class="thead-light w-100">
        <el-table-column label="排序" width="80">
          <template slot-scope="scope">
            <i class="el-icon-sort" style="font-size: 20px;cursor: pointer;"></i>
          </template>
        </el-table-column>
        <el-table-column label="名称" min-width="120" prop="name"></el-table-column>
        <el-table-column label="字段类型" min-width="200">
          <template slot-scope="scope">
            {{types[scope.row.type].name}}
          </template>
        </el-table-column>
        <el-table-column label="必填">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_must" :true-label="1" :false-label="0"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="显示">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_show" :true-label="1" :false-label="0"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="editConfig(scope.$index)">编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link :underline="false" type="danger" @click="removeConfig(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <add-button @click="addConfig" style="margin-top: 20px;" text="新增报名信息" direction="horizontal"
        height="32px"></add-button>
    </el-main>
    <el-dialog title="编辑字段" :visible.sync="openDialog" width="500px" :show-close="true"
      class="dialog-vertical" @close="cancels('CategoryName')">
      <el-form style="width: 100%" label-width="80px" label-position="right" size="medium" class="small-form">
        <el-form-item label="字段名称：" required>
          <el-input v-model="new_config.name" placeholder="请输入名称，不多于10个字" maxlength="10" show-word-limit>
          </el-input>
        </el-form-item>
        <!--                    <el-form-item label="排序：">-->
        <!--                        <el-input type="number" v-model="new_config.sort" placeholder="请输入整数"></el-input>-->
        <!--                    </el-form-item>-->
        <el-form-item label="输入类型：" required>
          <el-select v-model="new_config.type" class="w-100">
            <el-option v-for="(t, tIndex) in types" :key="t.id" :label="t.name" :value="t.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="输入限制：" v-if="new_config.type == 1 || new_config.type == 0">
          <el-select v-model="new_config.impose" class="w-100">
            <el-option v-for="(l, lIndex) in limits" :key="l.id" :label="l.name" :value="l.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选项内容：" required
          v-if="new_config.type == 2 || new_config.type == 3 || new_config.type == 4">
          <el-input type="textarea" v-model="new_config.options" placeholder='多个选项值，多个用"|"隔开，请不要使用空格！'>
          </el-input>
        </el-form-item>
        <el-form-item label="是否必填：">
          <el-radio-group v-model="new_config.is_must">
            <el-radio :label="1">必填</el-radio>
            <el-radio :label="0">非必填</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-radio-group v-model="new_config.is_show">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="openDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveNewConfig()">保存</el-button>
      </span>
    </el-dialog>
    <fixed-action-bar>
      <div v-show="isNext">
        <el-button v-if="!sign" type="primary" @click="upperNext">上一步</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
      <div v-show="!isNext && showNext">
        <el-button type="primary" @click="onNext">下一步</el-button>
        <el-button @click="cancel">取消</el-button>
        <!--                <el-button type="primary" @click="saveFormConfig">保存</el-button>-->
      </div>
    </fixed-action-bar>
  </div>
</template>

<script>
import SingleMediaWall from "../../common/components/SingleMediaWall";
import DefaultImageSelector from "../../common/components/DefaultImageSelector.vue";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import { getSave, setDetail } from "../api/add-signup";
import AddButton from "../../../base/components/Base/AddButton";
export default {
  components: {
    AddButton,
    SingleMediaWall,
    DefaultImageSelector,
    FixedActionBar,
  },
  data() {
    return {
      id: 0,
      fullscreen: false,
      loading: false,
      openDialog: false,
      images:
        window.serverConfig.VUE_APP_ADMINURL +
        "/signup/images/admin/sign_default.png",
      DefaultImage:
        window.serverConfig.VUE_APP_ADMINURL +
        "/signup/images/admin/sign_default.png",
      // 图片上传相关
      isOpenImages: false,

      isNext: false,
      showNext: true,
      detail: [],
      success_title: "",
      success_content: "",
      config: [
        {
          type: "person", //限定每人量
          num: 0, //人数
          status: "0", //是否启用   0 不启用  1启用
        },
        {
          type: "day", //限定每日量
          num: 0, //人数
          status: "0", //是否启用   0 不启用  1启用
        },
        {
          type: "total", //限定全部总量
          num: 0, //人数
          status: "0", //是否启用   0 不启用  1启用
        },
      ],

      title: "",
      limitList: [],
      start_time: "",
      end_time: "",
      share_image: "",
      form_config: [],

      isShow: false,
      new_config: {
        name: "",
        is_must: 0,
        is_show: 1,
        type: "",
        impose: "",
        sort: "",
        timestamp: 1,
        options: "",
      },

      types: [
        {
          id: 0,
          name: "单行文本",
        },
        {
          id: 1,
          name: "多行文本",
        },
        {
          id: 2,
          name: "单项选择",
        },
        {
          id: 3,
          name: "多项选择",
        },
        {
          id: 4,
          name: "下拉选择",
        },
        {
          id: 5,
          name: "图片上传",
        },
        {
          id: 6,
          name: "省市区",
        },
        // {
        //   id: 7,
        //   name: "视频上传",
        // },
        // {
        //   id: 8,
        //   name: "图片/视频上传",
        // },
      ],

      limits: [
        { id: 0, name: "英文汉字数字" },
        { id: 1, name: "英文大小写字符" },
        { id: 2, name: "0或正整数" },
        { id: 3, name: "小数" },
        { id: 4, name: "邮箱" },
        { id: 5, name: "手机号" },
        { id: 7, name: "不限制" },
      ],
      rules: {
        title: [{ required: true, message: "", trigger: "c" }],
        limit: [{ required: true, message: "", trigger: "c" }],
        date: [{ required: true, message: "", trigger: "c" }],
        person: [{ required: true, message: "", trigger: "c" }],
        day: [{ required: true, message: "", trigger: "c" }],
        total: [{ required: true, message: "", trigger: "c" }],
      },
      basePath: "",
      edit_index: -1,
      default_banner: "",
      sign: "",
    };
  },
  watch: {
    limitList(val) {
      for (let j = 0; j < this.config.length; j++) {
        this.config[j].status = 0;
      }
      for (let i = 0; i < val.length; i++) {
        let type = val[i];
        for (let j = 0; j < this.config.length; j++) {
          if (this.config[j].type === type) {
            this.config[j].status = 1;
          }
        }
      }
    },
  },
  mounted() {
    this.drag();
  },
  created() {
    this.id = this.$route.params.id;
    let sign = this.$route.params.sign;
    this.sign = this.$route.params.sign;
    if (this.id != 0) {
      this.loading = true;
      setDetail({ id: this.id })
        .then((res) => {
          if (sign) {
            this.showNext = false;
            this.isNext = true;
          }
          this.title = res.data.title;
          this.start_time = res.data.start_time * 1000;
          this.end_time = res.data.end_time * 1000;
          this.images =
            res.data.images instanceof Array
              ? res.data.images.length
                ? res.data.images[0]
                : this.DefaultImage
              : res.data.images;
          this.success_title = res.data.success_title;
          this.success_content = res.data.success_content;
          this.share_image = res.data.share_image;
          // 表单提交限制
          this.config = res.data.config;
          let personConfig = res.data.config[0];
          if (personConfig.status) {
            this.limitList.push(personConfig.type);
          }
          let dayConfig = res.data.config[1];
          if (dayConfig.status) {
            this.limitList.push(dayConfig.type);
          }
          let totalConfig = res.data.config[2];
          if (totalConfig.status) {
            this.limitList.push(totalConfig.type);
          }

          for (let i = 0; i < res.data.res.length; i++) {
            let current = res.data.res[i];
            let timestamp = new Date().getTime();
            this.form_config.push(
              Object.assign({}, current, {
                timestamp: timestamp + i,
              })
            );
          }
          this.loading = false;
        })
        .catch((err) => {});
    }
  },
  methods: {
    editConfig(i) {
      this.edit_index = i;
      this.new_config = Object.assign({}, this.form_config[i]);
      this.openDialog = true;
    },
    // 删除
    removeConfig(index) {
      this.$msgbox
        .confirm("确定要删除该数据吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.form_config.splice(index, 1);
        })
        .catch((err) => {});
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        // handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = [...this.form_config];
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
            this.form_config = arr;
          });
        },
      });
    },
    cancels() {
      this.openDialog = false;
    },

    saveNewConfig() {
      let config = JSON.parse(JSON.stringify(this.new_config));
      if (!config.name) {
        return alert("请填写字段名称");
      }
      switch (config.type) {
        case 0:
        case 1:
          if (config.impose === "") {
            //return alert('请选择输入限制')
          }
          break;
        case 2:
        case 3:
        case 4:
          if (!config.options) {
            return alert("请输入选项内容");
          }
          break;
        case 5:
        case 6:
        case 7:
        //break;
        case 8:
          break;
        default:
          return alert("请选择输入类型");
      }

      if (this.edit_index !== -1) {
        this.$set(this.form_config, this.edit_index, config);
      } else {
        this.form_config.push(config);
      }
      this.openDialog = false;
    },
    upperNext() {
      this.isNext = !this.isNext;
    },
    onNext() {
      if (!this.isNext) {
        if (!this.title) {
          return alert("请输入报名标题");
        }
        if (!this.limitList.length) {
          return alert("请至少选择一项表单提交限制");
        }
        if (!this.start_time) {
          return alert("请选择开始时间");
        }
        if (!this.end_time) {
          return alert("请选择结束时间");
        }
        for (let i = 0; i < this.limitList.length; i++) {
          let current = this.limitList[i];
          if (current === this.config[0].type && this.config[0].status) {
            if (this.config[0].num === "") {
              return alert("请输入每人报名数");
            } else if (!/^[1-9]\d*$/.test(this.config[0].num)) {
              return alert("每人报名数需输入大于 0 的整数");
            } else {
            }
          }
          if (current === this.config[1].type && this.config[1].status) {
            if (this.config[1].num === "") {
              return alert("请输入每日报名数");
            } else if (!/^[1-9]\d*$/.test(this.config[1].num)) {
              return alert("每日报名数需输入大于 0 的整数");
            } else {
            }
          }
          if (current === this.config[2].type && this.config[2].status) {
            if (this.config[2].num === "") {
              return alert("请输入报名总数");
            } else if (!/^[1-9]\d*$/.test(this.config[2].num)) {
              return alert("报名总数需输入大于 0 的整数");
            } else {
            }
          }
        }
      }
      this.isNext = !this.isNext;
    },
    // 取消保存
    cancel() {
      this.$router.go(-1);
    },
    addConfig() {
      this.edit_index = -1;
      this.openDialog = true;
      this.new_config.timestamp = new Date().getTime();
      this.new_config = {
        name: "",
        is_must: 0,
        is_show: 1,
        type: "",
        impose: "",
        sort: "",
        timestamp: 1,
        options: "",
      };
    },
    // 保存
    onSubmit() {
      this.loading = true;
      let form_config = JSON.parse(JSON.stringify(this.form_config));
      for (let i = 0; i < form_config.length; i++) {
        delete form_config[i].timestamp;
      }
      getSave({
        id: this.id,
        title: this.title,
        images: this.images.length ? [this.images] : "[]",
        start_time: this.start_time / 1000,
        end_time: this.end_time / 1000,
        success_title: this.success_title,
        success_content: this.success_content,
        share_image: this.share_image,
        config: this.config,
        res: form_config,
      })
        .then((res) => {
          this.$router.go(-1);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveFormConfig() {
      this.onSubmit();
    },
  },
};
</script>

<style scoped>
.el-form {
  width: 500px;
}
.inline-block {
  padding: 0 10px;
}
.note {
  color: rgba(0, 0, 0, 0.45);
}
.add-host-btn {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px dashed rgba(217, 217, 217, 1);
  cursor: pointer;
}
.m-t-15 {
  margin-top: 25px;
}
.m-t-8 {
  margin-top: 10px;
}
.img-tips {
  margin-top: 8px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 15px;
}
</style>
